import axios from '@axios'

export default {
  namespaced: true,
  mobile: {},
  getters: {},
  mutations: {},
  actions: {
    fetchData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`getcms/${userData.id}`,
            userData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    pricing(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`pricinginfo/${userData.id}`,
            userData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
